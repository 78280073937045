.back {
  background: linear-gradient(90deg, #1898d4, #0f6eaa);
}

@media only screen and (min-width: 1750px) {
  .slice0 {
    height: 500px;
  }
}

@media only screen and (min-width: 2000px) {
  .slice0 {
    height: 550px;
  }
}

@media only screen and (min-width: 2500px) {
  .slice0 {
    height: 600px;
  }
}

@media only screen and (min-width: 3000px) {
  .slice0 {
    height: 650px;
  }
}
