.serviceItem {
  ul {
    padding-left: 1rem;
    li {
      margin-top: 10px;
      position: relative;
      line-height: 120%;
      &::before {
        content: " \25CF";

        position: absolute;
        left: -15px;
      }
    }
  }
}
