.textbg {
  background: linear-gradient(
    180deg,
    rgba(83, 182, 152, 0) 0%,
    rgba(21, 124, 223, 0.5) 100%
  );
}

.imageBg {
  background: linear-gradient(
    0deg,
    rgba(1, 24, 57, 0.5) 0%,
    rgba(1, 24, 57, 0.5) 100%
  );
}
