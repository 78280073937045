.inputField {
  label {
    top: -2rem;
    transition: all 0.2s ease-in-out;
    z-index: 0;
  }
  textarea {
    z-index: 1;
    resize: none;
  }
  & textarea:focus + label {
    top: -3rem;
    transition: all 0.2s ease-in-out;
  }
}
