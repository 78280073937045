.pagination {
  ul {
    border: none !important;
    background: none !important;
    li {
      border: none !important;
      button {
        background: none !important;
        border: none !important;
        &:hover {
          color: #333 !important;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    li[aria-current="page"] {
      button {
        background: rgba(0, 176, 82, 0.5) !important;
        border-radius: 10px;
      }
    }
  }
}
